import moment from "moment/moment";
import { GET, POST, PUT, SUCCESS } from "../utils/const/http-const";
import { getHeaders } from "./utils";

export async function obtenerListaImagenEmpresa(combo = false) {
    try {
        let rows = [];
        const { code, body } = await fetch(
            "https://gestiondeflota.boltrack.net/apichecklistv2/api/imagen-empresa/obtener-lista-imagen-empresa/",
            {
                headers: getHeaders(),
                method: GET,
                // body: JSON.stringify({data: 'hello'}),
            }
        ).then((res) => res.json());
        if (code === SUCCESS) {
            if (combo === true) {
                for (let i = 0; i < body.length; i++) {
                    const element = body[i];
                    let obj = {};
                    obj.value = element.id;
                    obj.label = element.descripcion;
                    rows.push(obj);
                }
            }
            if (combo === false) {
                for (let i = 0; i < body.length; i++) {
                    body[i].fechaProceso = moment(body[i].fechaProceso).format('DD/MM/YYYY HH:mm')
                    body[i].defaultLabel = body[i].default === 1 ? 'SI' : 'NO';
                    rows.push(body[i]);
                }
            }
        }
        return rows;
    } catch (err) {
        console.log("obtenerListaImagenEmpresa err");
        console.log(err);
        return [];
    }
}

export async function guardarImagenEmpresa(obj) {
    try {
        let creado = false;
        const { code } = await fetch(
            "https://gestiondeflota.boltrack.net/apichecklistv2/api/imagen-empresa/guardar-imagen-empresa/",
            {
                headers: getHeaders(),
                method: POST,
                body: JSON.stringify(obj),
            }
        ).then((res) => res.json());
        console.log('code');
        console.log(code);
        if (code === SUCCESS) {
            creado = true;
        }
        return creado;
    } catch (err) {
        console.log("guardarImagenEmpresa err");
        console.log(err);
        return false;
    }
}

export async function deshabilitarImagenEmpresa(obj) {
    try {
        let result = false;
        const { code } = await fetch(
            "https://gestiondeflota.boltrack.net/apichecklistv2/api/imagen-empresa/deshabilitar-imagen-empresa/",
            {
                headers: getHeaders(),
                method: PUT,
                body: JSON.stringify(obj),
            }
        ).then((res) => res.json());
        // console.log('code');
        // console.log(code);
        // console.log('body');
        // console.log(body);
        if (code === SUCCESS) {
            result = true;
        }
        return result;
    } catch (err) {
        console.log("deshabilitarImagenEmpresa err");
        console.log(err);
        return false;
    }
}

export async function setDefaultImagenEmpresa(obj) {
    try {
        let result = false;
        const { code } = await fetch(
            "https://gestiondeflota.boltrack.net/apichecklistv2/api/imagen-empresa/default-imagen-empresa/",
            {
                headers: getHeaders(),
                method: PUT,
                body: JSON.stringify(obj),
            }
        ).then((res) => res.json());
        // console.log('code');
        // console.log(code);
        // console.log('body');
        // console.log(body);
        if (code === SUCCESS) {
            result = true;
        }
        return result;
    } catch (err) {
        console.log("setDefaultImagenEmpresa err");
        console.log(err);
        return false;
    }
}

export async function removeDefaultImagenEmpresa(obj) {
    try {
        let result = false;
        const { code } = await fetch(
            "https://gestiondeflota.boltrack.net/apichecklistv2/api/imagen-empresa/remove-default-imagen-empresa/",
            {
                headers: getHeaders(),
                method: PUT,
                body: JSON.stringify(obj),
            }
        ).then((res) => res.json());
        // console.log('code');
        // console.log(code);
        // console.log('body');
        // console.log(body);
        if (code === SUCCESS) {
            result = true;
        }
        return result;
    } catch (err) {
        console.log("setDefaultImagenEmpresa err");
        console.log(err);
        return false;
    }
}

export async function obtenerImagenDefaultEmpresa(combo = false) {
    try {
        let response = null;
        const { code, body } = await fetch(
            "https://gestiondeflota.boltrack.net/apichecklistv2/api/imagen-empresa/obtener-imagen-default-empresa/",
            {
                headers: getHeaders(),
                method: GET,
                // body: JSON.stringify({data: 'hello'}),
            }
        ).then((res) => res.json());
        if (code === SUCCESS) {
            if (Array.isArray(body) === true && body.length > 0) {
                response = body[0].urlImagen;
            }
        }
        return response;
    } catch (err) {
        console.log("obtenerImagenDefaultEmpresa err");
        console.log(err);
        return null;
    }
}

