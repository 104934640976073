import React from "react";
import { Switch, Route, useRouteMatch } from "react-router-dom";
// import LayoutUsuario from "../../components/layout-usuario";
import DashboardScreen from "./index";
import DetalleChecklist from "./detalle-checklist";

const RouterDashboard = () => {
  // console.log('RouterDashboard');
  const { path } = useRouteMatch();
  // console.log(path);
  return (
    // <LayoutUsuario>
    <Switch>
      <Route exact path={`${path}`}>
        <DashboardScreen />
      </Route>

      <Route path={`${path}/detalle-checklist`}>
        <DetalleChecklist />
      </Route>

      {/*<Route path={`${path}/usuario-web`}>
          <UsuarioWeb />
        </Route> */}

    </Switch>
    // </LayoutUsuario>
  );
};

export default RouterDashboard;
