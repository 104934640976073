import shortid from "shortid";

const ComponentDetalleChecklist = ({ detalleChecklist, }) => {


    return (
        <div style={{ paddingLeft: '10px', paddingRight: '10px' }}>
            {detalleChecklist.map((seccion) => (
                <div
                    key={shortid.generate()}
                    style={{
                        marginBottom: '20px',
                        padding: '10px',
                        backgroundColor: '#6c757d', // Bootstrap "secondary" color
                        color: 'white',
                        borderRadius: '5px',
                    }}
                >
                    <label style={{ fontSize: '1.25rem', fontWeight: 'bold' }}>
                        {seccion.nombreSeccion}
                    </label>
                    <div
                        style={{
                            display: 'flex',
                            borderBottom: '1px solid #ccc',
                            padding: '10px 0',
                        }}
                    >
                        <div style={{ flex: 8 }}>
                            <label style={{ fontWeight: 'bold' }}>PREGUNTA</label>
                        </div>
                        <div style={{ flex: 4 }}>
                            <label style={{ fontWeight: 'bold' }}>RESPUESTA</label>
                        </div>
                    </div>
                    {seccion.preguntas.map((pregunta) => (
                        <>
                            <div
                                key={shortid.generate()}
                                style={{
                                    display: 'flex',
                                    borderBottom: '1px solid #ccc',
                                    padding: '10px 0',
                                    backgroundColor: '#f8f9fa',
                                }}
                            >
                                <div style={{ flex: 8 }}>
                                    <label style={{ color: '#212529' }}>{pregunta.labelPregunta}</label>
                                </div>
                                <div style={{ flex: 4 }}>
                                    <p style={{ color: '#212529' }}>{pregunta.labelOpcionRespuesta}</p>
                                    <p style={{ color: '#212529' }}>{pregunta.respuesta}</p>
                                    {pregunta.urlArchivo &&
                                        <p style={{ color: '#212529' }}>
                                            <a
                                                href={pregunta.urlArchivo}
                                                target="_blank"
                                                rel="noopener noreferrer"
                                                style={{ color: '#212529', textDecoration: 'none' }}
                                            >
                                                VER IMAGEN
                                            </a>
                                        </p>
                                    }
                                </div>
                            </div>
                            {pregunta.urlArchivo &&
                                <div
                                    style={{
                                        display: 'flex',
                                        padding: '10px 0',
                                        backgroundColor: '#f8f9fa',
                                        justifyContent: 'flex-end',
                                    }}
                                >
                                    <a
                                        href={pregunta.urlArchivo}
                                        target="_blank"
                                        rel="noopener noreferrer"
                                        style={{ color: '#212529', textDecoration: 'none' }}
                                    >
                                        {pregunta.urlArchivo}
                                    </a>
                                </div>
                            }
                        </>
                    ))}
                </div>
            ))}
        </div>

    );
}

export default ComponentDetalleChecklist;