import { SUCCESS } from "../../utils/const/http-const";
import { getHeaders } from "../utils";
import { GET } from "../../utils/const/http-const";

export async function obtenerInfoEmpresa() {
    try {
        let response = {};
        const { code, body } = await fetch(
            "https://gestiondeflota.boltrack.net/apirastreogestion/api/empresa/",
            {
                headers: getHeaders(),
                method: GET,
                // body: JSON.stringify({data: 'hello'}),
            }
        ).then((res) => res.json());
        if (code === SUCCESS && Array.isArray(body) === true && body.length > 0) {
            response = body[0];
        }
        return response;
    } catch (err) {
        console.log("obtenerListaChecklist err");
        console.log(err);
        return {};
    }
}