import React from "react";
import { Switch, Route, useRouteMatch } from "react-router-dom";
import LayoutChecklist from "../../components/layout-checklist";
import AlertaChecklistScreen from ".";

const RouterAlertasChecklist = () => {
  const { path } = useRouteMatch();
  return (
    <LayoutChecklist>
      <Switch>

        <Route exact path={`${path}`}>
          <AlertaChecklistScreen />
        </Route>


      </Switch>
    </LayoutChecklist>
  );
};

export default RouterAlertasChecklist;
