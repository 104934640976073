import { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { isTokenExpire } from "../utils/token";
import {
  // Bars3BottomLeftIcon,
  // Squares2X2Icon,
  // XMarkIcon,
  // MapIcon,
  // UserCircleIcon,
  // BellAlertIcon,
  // UserGroupIcon,
  Cog8ToothIcon,
  ClipboardDocumentCheckIcon,
  ChartBarSquareIcon,
  // CubeTransparentIcon,
  // DocumentTextIcon,
  ArrowLeftStartOnRectangleIcon,
  ChevronDownIcon,
  ChevronRightIcon,
  ChevronLeftIcon,
  UserGroupIcon,
} from "@heroicons/react/24/outline";
// import { Menu, Transition } from "@headlessui/react";

import Logo from "../assets/boltrack-logo-sm.svg";
import { COLOR_AZUL_MAIN } from "../utils/const/colors-const";
import { STRING_FALSE, STRING_SIDEBAR_EXPANDED, STRING_TRUE } from "../utils/const/string-const";

const getSideBarStatus = () => {
  try {
    const sideBar = localStorage.getItem(STRING_SIDEBAR_EXPANDED);
    if (sideBar !== null) {
      if (sideBar === STRING_FALSE) {
        return false;
      }
      if (sideBar === STRING_TRUE) {
        return true;
      }
    }
    return false;
  } catch (err) {
    return false;
  }
}

function Layout({ children }) {

  const [expireToken, setExpireToken] = useState(true);
  const [expandedMenus, setExpandedMenus] = useState({});
  const [isSidebarExpanded, setIsSidebarExpanded] = useState(getSideBarStatus());
  const history = useHistory();

  const sidebarNavigation = [
    { name: "Dashboard", href: "/dashboard", icon: ChartBarSquareIcon },
    { name: "Checklist", href: "/checklist", icon: ClipboardDocumentCheckIcon, },
    /*{ name: "Seguimiento", href: "/seguimiento", icon: MapIcon },
    { name: "Eventos", href: "/eventos", icon: Squares2X2Icon },
    { name: "Descargas", href: "/descargas", icon: ArrowRightOnRectangleIcon },
    { name: "Cargas", href: "/cargas", icon: ArrowLeftOnRectangleIcon },*/
    { name: "Usuarios", href: "/usuario", icon: UserGroupIcon },
    { name: "Configuracion", href: "/configuracion", icon: Cog8ToothIcon },
    /*{ name: "Reportes", href: "/reportes", icon: DocumentTextIcon },
    { name: "Georuta", href: "/georuta", icon: CubeTransparentIcon },
    { name: "Alertas", href: "/alertas", icon: BellAlertIcon },*/
    { name: "Salir", href: "/salir", icon: ArrowLeftStartOnRectangleIcon },
  ].map((menuItem) => ({
    ...menuItem,
    getHref: () => (menuItem.primary ? `/` : `/${menuItem.href}`),
    current: function getCurrent(currentPath) {
      return currentPath === this.getHref();
    },
  }));



  const nextPage = (page) => {
    try {
      history.push(`${page}`);
    } catch (err) {
      console.log("nextPage err");
      console.log(err);
    }
  };

  useEffect(() => {
    const sideBar = localStorage.getItem(STRING_SIDEBAR_EXPANDED);
    // console.log('sideBar');
    // console.log(sideBar);
    // console.log(typeof sideBar);
    if (sideBar !== null) {
      if (sideBar === STRING_FALSE) {
        // console.log('side bar false');
        setIsSidebarExpanded(false);
      }
      if (sideBar === STRING_TRUE) {
        // console.log('side bar true');
        setIsSidebarExpanded(true);
      }
    }
    const resp = isTokenExpire();
    if (resp) {
      nextPage("/login");
    } else {
      setExpireToken(false);
    }
  }, []);

  // function classNames(...classes) {
  //   return classes.filter(Boolean).join(" ");
  // }

  if (expireToken) {
    return <div></div>;
  }

  const handleToggleMenu = (name) => {
    setExpandedMenus((prev) => ({ ...prev, [name]: !prev[name] }));
  };

  const handleToggleSidebar = () => {
    localStorage.setItem(STRING_SIDEBAR_EXPANDED, !isSidebarExpanded);
    setIsSidebarExpanded(prevState => !prevState);
  };

  const renderMenuItems = (items) => {
    // console.log('renderMenuItems');
    // console.log(items);
    return items.map((item, index) => {
      const isExpanded = expandedMenus[item.name];
      return (
        <div key={index}>
          <a
            key={item.name}
            href={item.href}
          >
            <div
              className="flex items-center justify-between p-2 text-gray-700 hover:bg-blueBoltrack2 cursor-pointer"
              onClick={() => handleToggleMenu(item.name)}
              title={item.name}
            >
              <div className="flex items-center">
                {item.icon && <item.icon className="h-5 w-5 mr-2 text-white" />}
                {isSidebarExpanded && <span className="text-white">{item.name}</span>}
              </div>
              {item.children && item.children.length > 0 && (
                isExpanded ? <ChevronDownIcon className="h-5 w-5" /> : <ChevronRightIcon className="h-5 w-5" />
              )}
            </div>
            <div
              className={`overflow-hidden transition-height ${isExpanded ? 'max-h-96' : 'max-h-0'}`}
              style={{ transitionDuration: '500ms' }}
            >
              {isExpanded && item.children && (
                <div className="ml-4">
                  {renderMenuItems(item.children)}
                </div>
              )}
            </div>
          </a>
        </div>
      );
    });
  };

  return (
    <>
      <div className="flex h-[100vh]">
        <div className={`flex flex-col h-screen transition-width duration-500 ${isSidebarExpanded ? 'w-64' : 'w-20'} `} style={{ backgroundColor: `${COLOR_AZUL_MAIN}` }}>
          <div className="flex items-center justify-between p-4">
            <div className="flex items-center">
              <img className="h-8 w-auto " alt="Checklist" src={Logo} />
              {isSidebarExpanded && <span className="text-xl font-bold text-white pl-4">Checklist</span>}
            </div>
            <button onClick={handleToggleSidebar}>
              {isSidebarExpanded ? <ChevronLeftIcon className="h-5 w-5 text-white" strokeWidth={4} /> : <ChevronRightIcon className="h-5 w-5 text-white" strokeWidth={4} />}
            </button>
          </div>
          <div className="flex-grow p-4 overflow-y-auto">
            {renderMenuItems(sidebarNavigation)}
          </div>
          <div className="p-4 text-gray-500 text-xs">
            Version: 1.1.1
          </div>
        </div>

        {/* Content area */}
        <div className="flex flex-1 flex-col overflow-hidden ">
          <div className="flex flex-1 items-stretch overflow-hidden">
            <aside className=" w-full overflow-y-auto  border-gray-200 bg-white lg:block">
              {children}
            </aside>
          </div>
        </div>

      </div>
    </>
  );
}
export default Layout;
