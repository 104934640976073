import React from "react";
import { Switch, Route, useRouteMatch } from "react-router-dom";
import Layout from "../components/layout";
// import DashboardScreen from "./dashboard/index";
import Salir from "./salir";
import RouterConfiguracion from "./configuracion/router";
import RouterChecklist from "./checklist/router";
import RouterUsuario from "./usuario/router";
import RouterAlertasChecklist from "./alertas/router";
import RouterDashboard from "./dashboard/router";
// import DetalleChecklistScreen from "./dashboard/detalle-checklist";

const RouterPage = () => {
  const { path } = useRouteMatch();
  // console.log('routerPage');
  // console.log(path);
  return (
    <Switch>
      <Layout>
        <Route path={`${path}alertas`}>
          <RouterAlertasChecklist />
        </Route>
        <Route path={`${path}checklist`}>
          <RouterChecklist />
        </Route>
        <Route path={`${path}configuracion`}>
          <RouterConfiguracion />
        </Route>
        <Route path={`${path}usuario`}>
          <RouterUsuario />
        </Route>
        <Route path={`${path}salir`}>
          <Salir />
        </Route>
        <Route path={`${path}dashboard`}>
          {/* <DashboardScreen /> */}
          <RouterDashboard />
        </Route>
        <Route exact path={`${path}`}>
          <RouterDashboard />
        </Route>

        {/* <Route exact path={`${path}dashboard/detalle-checklist`}>
          <DetalleChecklistScreen />
        </Route> */}
      </Layout>
    </Switch>
  );
};

export default RouterPage;
