import { useState, useEffect } from "react";
import cargandoCard from "../../components/cargando-card";
import sinAccesoCard from "../../components/sin-acceso-card";
import ModalConfirmar from "../../components/modal-confirmar";
import Grid from "../../components/grid";
import { ESTILO_BOTON_LISTAR_ATRAS, } from "../../utils/const/estilos-const";
import { useHistory } from "react-router-dom";
import { INT_TIPO_USUARIO_ADMINISTRADOR_WEB } from "../../utils/const/int-const";
import { deshabilitarProgramacionAlertaChecklist, obtenerProgramacionAlertaChecklist } from "../../api/checklist";
import { TRUE } from "../../utils/const/bool-const";

const idTipoUsuario = parseInt(localStorage.getItem("tipoUsuarioChecklist"));

export default function AlertaChecklistScreen() {

    const headers = [
        { key: "acciones", title: "ACCIONES" },
        { key: "id", title: "ID", visible: false, },
        { key: "valueVehiculo", title: "VALUE VEHICULO", visible: false, filter: true, editLabel: true, },
        { key: "labelVehiculo", title: "VEHICULO", visible: true, filter: true, editLabel: false, },
        { key: "fechaAlertaChecklist", title: "FECHA ALERTA", visible: true, filter: true, editLabel: true, },
    ];

    const history = useHistory();
    const [cargandoPage, setCargandoPage] = useState(true);
    const [tieneAccesoAlModulo, setTieneAccesoAlModulo] = useState(false);
    const [listaElementos, setListaElementos] = useState([]);
    const [elementoSelected, setElementoSelected] = useState(null);
    const [elemento, setElemento] = useState({});
    const [modalConfirmarDeshabilitarVisible, setModalConfirmarDeshabilitarVisible] = useState(false);
    // const [permisos, setPermisos] = useState(null);
    const [modalCrearChecklistVisible, setModalCrearChecklistVisible] = useState(false);

    useEffect(() => {
        iniciar();
    }, []);

    const iniciar = async () => {
        try {
            /*let obj = {};
            obj.idMenuPrimerNivel = INT_ID_MODULO_CHECKLIST;
            obj.idMenuSegundoNivel = INT_ID_SEGUNDO_NIVEL_ADMINISTRACION_CHECKLIST;
            obj.idMenuTercerNivel = INT_ID_TERCER_NIVEL_CHECKLIST_OPCIONES_RESPUESTA;
            let obtenerPermisos = await obtenerPermisosPorNivelUsuarioWeb(obj);
            let permiso = obtenerPermisos[0];
            setPermisos(permiso);
            */
            let permiso = null;
            let lista = [];

            if (idTipoUsuario === INT_TIPO_USUARIO_ADMINISTRADOR_WEB) {
                // accesoBotonNuevo = '';
                setTieneAccesoAlModulo(true);
                lista = await obtenerProgramacionAlertaChecklist();
            }
            if (idTipoUsuario !== INT_TIPO_USUARIO_ADMINISTRADOR_WEB) {
                if (permiso.accesoAlModulo === TRUE) {
                    setTieneAccesoAlModulo(true);
                    lista = await obtenerProgramacionAlertaChecklist();
                }
            }

            agregarAccionesALista(lista, permiso);
            setCargandoPage(false);
        } catch (err) {
            setTieneAccesoAlModulo(false);
            console.log('iniciar err');
            console.log(err);
        }
    }

    const agregarAccionesALista = (lista, permiso) => {
        try {
            // console.log('agregarAccionesALista');
            // console.log(lista);
            if (lista.length === 0) {
                setListaElementos(lista);
            }
            if (lista.length > 0) {
                // const objModificar = {
                //     label: "Modificar",
                //     color: "green",
                //     onClick: (param) => editarElementoSelected(param),
                // };
                const objDeshabilitar = {
                    label: "Deshabilitar",
                    color: "red",
                    onClick: (param) => cambiarEstado(param),
                };
                // if (idTipoUsuario !== INT_TIPO_USUARIO_ADMINISTRADOR_WEB) {
                // if (permiso.nuevo === TRUE) {
                // accesoBotonNuevo = '';
                // }
                // }
                for (let i = 0; i < lista.length; i++) {
                    let acciones = [];
                    if (idTipoUsuario === INT_TIPO_USUARIO_ADMINISTRADOR_WEB) {
                        // acciones.push(objFormulario);
                        // acciones.push(objModificar);
                        acciones.push(objDeshabilitar);
                    }
                    if (idTipoUsuario !== INT_TIPO_USUARIO_ADMINISTRADOR_WEB) {
                        // if (permiso.modificar === TRUE) {
                        // acciones.push(objFormulario);
                        // acciones.push(objModificar);
                        // }
                        // if (permiso.eliminarDeshabilitar === TRUE) {
                        acciones.push(objDeshabilitar);
                        // }
                    }
                    lista[i].acciones = acciones;
                }
                setListaElementos(lista);
            }
        } catch (err) {
            console.log('agregarAccionesALista err');
            console.log(err);
        }
    }

    // const editarElementoSelected = (data) => {
    //     try {
    //         console.log("editarElementoSelected");
    //         console.log(data);
    //         if (data.acciones !== undefined) {
    //             delete data.acciones;
    //         }
    //         let objData = {};
    //         objData.id = data.id;
    //         sharedNextPage("checklist/preguntas-checklist", objData, history);
    //     } catch (err) {
    //         console.log("editarElementoSelected err");
    //         console.log(err);
    //     }
    // };

    const cambiarEstado = async (data) => {
        try {
            console.log("cambiarEstado");
            console.log(data);
            setElementoSelected(data);
            setModalConfirmarDeshabilitarVisible(true);
        } catch (err) {
            console.log("cambiarEstado err");
            console.log(err);
        }
    };

    const handleAtrasClick = () => {
        history.goBack();
    }

    const handleConfirmModal = async () => {
        setModalConfirmarDeshabilitarVisible(false);
        if (elementoSelected == null) {
            return;
        }
        let objEnviar = {};
        objEnviar.id = elementoSelected.id;
        if (elementoSelected.id !== 0) {
            // console.log('crear correo');
            const deshabilitado = await deshabilitarProgramacionAlertaChecklist(objEnviar);
            // console.log('creado');
            // console.log(creado);
            if (deshabilitado === true) {
                alert('Se deshabilito correctamente');
                reset();
            }
            if (deshabilitado === false) {
                alert('No se pudo deshabilitar');
            }
        }
    }

    const reset = async () => {
        setCargandoPage(true);
        iniciar();
    }

    const render = () => {
        try {
            if (cargandoPage === true) {
                return cargandoCard();
            }
            if (tieneAccesoAlModulo === false) {
                return sinAccesoCard();
            }
            return (
                <div>
                    <div className="pt-4 px-4 flex justify-between">
                        <div className="flex justify-center align-center">
                            <label
                                style={{
                                    paddingLeft: 10,
                                    fontWeight: 'bold',
                                }}
                            >
                                ALERTAS CHECKLIST
                            </label>
                        </div>
                        <div className="flex justify-center">
                            <button
                                type="button"
                                className={ESTILO_BOTON_LISTAR_ATRAS}
                                onClick={handleAtrasClick}
                            >
                                ATRAS
                            </button>
                        </div>
                    </div>
                    <Grid rows={listaElementos} headers={headers} />
                    <ModalConfirmar
                        isOpen={modalConfirmarDeshabilitarVisible}
                        onCancel={() => { setModalConfirmarDeshabilitarVisible(false); }}
                        onConfirm={handleConfirmModal}
                        message="¿Estás de deshabilitar programacion Alerta?"
                    />
                </div>
            );
        } catch (err) {
            console.log('render err dashboard');
            console.log(err);
            return (
                <div className="m-8" >
                    <label>{JSON.stringify(err)}</label>
                </div>
            );
        }
    }

    return render();
}