import React, { useState } from 'react';
import {
    INT_PREGUNTA_COMENTARIO, INT_PREGUNTA_FIRMA, INT_PREGUNTA_IMAGEN,
    INT_PREGUNTA_SELECCION_SIMPLE,
    STRING_PREGUNTA_COMENTARIO, STRING_PREGUNTA_FIRMA, STRING_PREGUNTA_IMAGEN,
    STRING_PREGUNTA_SELECCION_SIMPLE
} from '../utils/const/tipo-pregunta-const';
import Plus from 'feather-icons-react/build/IconComponents/Plus';
import { ChevronDown } from 'feather-icons-react/build/IconComponents';

const DropdownPregunta = ({ handleAddPregunta, indexSeccion }) => {
    const [isOpen, setIsOpen] = useState(false);

    const toggleDropdown = () => setIsOpen(!isOpen);

    const onPressOption = (tipoPregunta) => {
        handleAddPregunta(tipoPregunta, indexSeccion);
        setIsOpen(false);
    }

    return (
        <div className="relative inline-block text-left">
            <button
                className="inline-flex justify-center w-full rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                onClick={toggleDropdown}
            >
                <div className="relative">
                    <Plus
                        className={`transition-opacity duration-500 ease-in-out absolute ${isOpen ? 'opacity-0' : 'opacity-100'}`}
                    />
                    <ChevronDown
                        className={`transition-opacity duration-500 ease-in-out ${isOpen ? 'opacity-100' : 'opacity-0'}`}
                    />
                </div>
                <span className="ml-2">PREGUNTA</span>
            </button>
            {isOpen && (
                <div className="origin-top-right absolute right-0 mt-2 w-56 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none">
                    <div className="py-1" role="menu" aria-orientation="vertical" aria-labelledby="options-menu">
                        <a
                            className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100"
                            role="menuitem"
                            onClick={() => { onPressOption(INT_PREGUNTA_SELECCION_SIMPLE) }}
                        >
                            {STRING_PREGUNTA_SELECCION_SIMPLE}
                        </a>
                        {/* <a
                            className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100"
                            role="menuitem"
                            onClick={() => { onPressOption(INT_PREGUNTA_SELECCION_MULTIPLE) }}
                        >
                            {STRING_PREGUNTA_SELECCION_MULTIPLE}
                        </a> */}
                        <a
                            className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100"
                            role="menuitem"
                            onClick={() => { onPressOption(INT_PREGUNTA_IMAGEN) }}
                        >
                            {STRING_PREGUNTA_IMAGEN}
                        </a>
                        <a
                            className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100"
                            role="menuitem"
                            onClick={() => { onPressOption(INT_PREGUNTA_COMENTARIO) }}
                        >
                            {STRING_PREGUNTA_COMENTARIO}
                        </a>
                        <a
                            className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100"
                            role="menuitem"
                            onClick={() => { onPressOption(INT_PREGUNTA_FIRMA) }}
                        >
                            {STRING_PREGUNTA_FIRMA}
                        </a>
                    </div>
                </div>
            )}
        </div>
    );
};

export default DropdownPregunta;
