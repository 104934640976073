import { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import Grid from "../../../components/grid";
import { ESTILO_BOTON_LISTAR_ATRAS, ESTILO_BOTON_LISTAR_NUEVO } from "../../../utils/const/estilos-const";
import ModalConfirmar from "../../../components/modal-confirmar";
import "../../../style/style-cargando.css";
import {
  INT_TIPO_USUARIO_ADMINISTRADOR_WEB,
} from "../../../utils/const/int-const";
// import { obtenerPermisosPorNivelUsuarioWeb } from "../../../api/permisos/permisos";
import { TRUE } from "../../../utils/const/bool-const";
import { deshabilitarOpcionRespuesta, obtenerListaTipoOpcionesRespuesta } from "../../../api/configuracion";
import cargandoCard from "../../../components/cargando-card";
import sinAccesoCard from "../../../components/sin-acceso-card";
import { Settings } from "feather-icons-react/build/IconComponents";
import { STRING_DEFAULT_OPCION_RESPUESTA_COLOR } from "../../../utils/const/string-const";
import { sharedNextPage } from "../../../utils/shared-functions";

const idTipoUsuario = parseInt(localStorage.getItem("tipoUsuarioChecklist"));
let accesoBotonNuevo = 'none';


export default function ListarOpcionesRespuesta() {
  const headers = [
    { key: "acciones", title: "ACCIONES" },
    { key: "id", title: "ID", visible: false, },
    { key: "descripcion", title: "DESCRIPCION", visible: true, filter: true, editLabel: true, },
    { key: "color", title: "COLOR", visible: true, filter: true, },
    { key: "enviarCorreo", title: "ENVIAR CORREO", visible: false, filter: true, },
    { key: "tomarFoto", title: "TOMAR FOTO", visible: false, filter: true, },
    { key: "notaObservacion", title: "NOTA/OBSERVACION", visible: false, filter: true, },
    { key: "enviarCorreoLabel", title: "ENVIAR CORREO", visible: true, filter: true, },
    { key: "tomarFotoLabel", title: "TOMAR FOTO", visible: true, filter: true, },
    { key: "pedirFechaLabel", title: "PEDIR FECHA", visible: true, filter: true, },
    { key: "pedirFecha", title: "PEDIR FECHA", visible: false, filter: true, },
    { key: "criticoLabel", title: "CRITICO", visible: true, filter: true, },
    { key: "critico", title: "CRITICO", visible: false, filter: true, },
    { key: "notaObservacionLabel", title: "NOTA/OBSERVACION", visible: true, filter: true, },
    { key: "correos", title: "CORREOS", visible: true, filter: true, isJson: true, },
    { key: "fechaProceso", title: "FECHA PROCESO", visible: true, filter: true },
  ];
  const history = useHistory();
  const [cargandoPage, setCargandoPage] = useState(true);
  const [listaOpcionesRespuesta, setListaOpcionesRespuesta] = useState([]);
  const [modalConfirmarDeshabilitarVisible, setModalConfirmarDeshabilitarVisible] = useState(false);
  const [opcionRespuestaSelected, setOpcionRespuestaSelected] = useState(null);
  // const [permisos, setPermisos] = useState(null);
  const [tieneAccesoAlModulo, setTieneAccesoAlModulo] = useState(true);

  useEffect(() => {
    iniciar();
  }, []);

  const iniciar = async () => {
    try {
      /*let obj = {};
      obj.idMenuPrimerNivel = INT_ID_MODULO_CHECKLIST;
      obj.idMenuSegundoNivel = INT_ID_SEGUNDO_NIVEL_ADMINISTRACION_CHECKLIST;
      obj.idMenuTercerNivel = INT_ID_TERCER_NIVEL_CHECKLIST_OPCIONES_RESPUESTA;
      let obtenerPermisos = await obtenerPermisosPorNivelUsuarioWeb(obj);
      let permiso = obtenerPermisos[0];
      setPermisos(permiso);
      */
      let permiso = null;
      let lista = [];

      if (idTipoUsuario === INT_TIPO_USUARIO_ADMINISTRADOR_WEB) {
        accesoBotonNuevo = '';
        setTieneAccesoAlModulo(true);
        lista = await obtenerListaTipoOpcionesRespuesta(false);
      }
      if (idTipoUsuario !== INT_TIPO_USUARIO_ADMINISTRADOR_WEB) {
        if (permiso.accesoAlModulo === TRUE) {
          setTieneAccesoAlModulo(true);
          lista = await obtenerListaTipoOpcionesRespuesta(false);
        }
      }

      agregarAccionesALista(lista, permiso);
      setCargandoPage(false);
    } catch (err) {
      console.log('iniciar err');
      console.log(err);
      setCargandoPage(false);
    }
  }

  const agregarAccionesALista = (lista, permiso) => {
    try {
      // console.log('agregarAccionesALista');
      // console.log(lista);
      if (lista.length === 0) {
        setListaOpcionesRespuesta(lista);
      }
      if (lista.length > 0) {
        const objModificar = {
          label: "Modificar",
          color: "green",
          onClick: (param) => editarOpcionRespuesta(param),
        };
        const objDeshabilitar = {
          label: "Deshabilitar",
          color: "red",
          onClick: (param) => cambiarEstado(param),
        };
        // if (idTipoUsuario !== INT_TIPO_USUARIO_ADMINISTRADOR_WEB) {
        // if (permiso.nuevo === TRUE) {
        accesoBotonNuevo = '';
        // }
        // }
        for (let i = 0; i < lista.length; i++) {
          let acciones = [];
          if (idTipoUsuario === INT_TIPO_USUARIO_ADMINISTRADOR_WEB) {
            acciones.push(objModificar);
            acciones.push(objDeshabilitar);
          }
          if (idTipoUsuario !== INT_TIPO_USUARIO_ADMINISTRADOR_WEB) {
            // if (permiso.modificar === TRUE) {
            acciones.push(objModificar);
            // }
            // if (permiso.eliminarDeshabilitar === TRUE) {
            acciones.push(objDeshabilitar);
            // }
          }
          lista[i].acciones = acciones;
        }
        setListaOpcionesRespuesta(lista);
      }
    } catch (err) {
      console.log('agregarAccionesALista err');
      console.log(err);
    }
  }

  const editarOpcionRespuesta = (data) => {
    try {
      // console.log("editarOpcionRespuesta");
      // console.log(data);
      if (data.acciones !== undefined) {
        delete data.acciones;
      }
      let obj = {};
      obj.titulo = "MODIFICAR OPCION RESPUESTA";
      obj.params = data;
      // console.log('obj');
      // console.log(obj);
      sharedNextPage("opciones-respuesta", obj, history);
    } catch (err) {
      console.log("editarOpcionRespuesta err");
      console.log(err);
    }
  };

  const cambiarEstado = async (data) => {
    try {
      console.log("cambiarEstado");
      console.log(data);
      setOpcionRespuestaSelected(data);
      setModalConfirmarDeshabilitarVisible(true);
    } catch (err) {
      console.log("cambiarEstado err");
      console.log(err);
    }
  };

  const handleNuevoClick = () => {
    // console.log("handleNuevoClick");
    let obj = {};
    let data = {};
    data.id = 0;
    data.descripcion = "";
    obj.titulo = "CREAR OPCION RESPUESTA";
    data.enviarCorreo = false;
    data.tomarFoto = false;
    data.pedirFecha = false;
    data.critico = false;
    data.notaObservacion = false;
    data.correos = null;
    data.color = STRING_DEFAULT_OPCION_RESPUESTA_COLOR;
    obj.params = data;
    sharedNextPage("opciones-respuesta", obj, history);
  }

  const handleAtrasClick = () => {
    history.goBack();
  }

  const reset = async () => {
    setCargandoPage(true);
    iniciar();
  }

  const handleCancelModal = () => {
    setModalConfirmarDeshabilitarVisible(false);
  }

  const handleConfirmModal = async () => {
    setModalConfirmarDeshabilitarVisible(false);
    if (opcionRespuestaSelected == null) {
      return;
    }
    let objEnviar = {};
    objEnviar.id = opcionRespuestaSelected.id;
    if (opcionRespuestaSelected.id > 0) {
      // console.log('crear correo');
      let deshabilitado = await deshabilitarOpcionRespuesta(objEnviar);
      // console.log('creado');
      // console.log(creado);
      if (deshabilitado === true) {
        alert('Se deshabilito correctamente');
        reset();
      }
      if (deshabilitado === false) {
        alert('No se pudo deshabilitar');
      }
    }
  }

  const render = () => {
    try {
      // console.log('listar correo render');
      if (cargandoPage === true) {
        return cargandoCard();
      }
      if (tieneAccesoAlModulo === false) {
        return sinAccesoCard();
      }
      return (
        <div>
          <div
            style={{
              paddingTop: 16,
              paddingLeft: 16,
              paddingRight: 16,
              display: 'flex',
              justifyContent: 'space-between'
            }}
          >
            <label
              style={{
                paddingLeft: 10,
                fontWeight: 'bold',
              }}
            >
              GESTIONAR OPCIONES RESPUESTA
            </label>
            <div className="flex justify-center">
              <button
                type="button"
                className={ESTILO_BOTON_LISTAR_NUEVO}
                onClick={handleNuevoClick}
                style={{
                  display: accesoBotonNuevo,
                }}
              >
                NUEVO
              </button>
              <button
                type="button"
                className={ESTILO_BOTON_LISTAR_ATRAS}
                onClick={handleAtrasClick}
              >
                ATRAS
              </button>
              <button
                type="button"
                className={ESTILO_BOTON_LISTAR_ATRAS}
                onClick={handleAtrasClick}
              >
                <Settings />
              </button>
            </div>
          </div>
          <Grid rows={listaOpcionesRespuesta} headers={headers} />
          <ModalConfirmar
            isOpen={modalConfirmarDeshabilitarVisible}
            onCancel={handleCancelModal}
            onConfirm={handleConfirmModal}
            message="¿Estás de deshabilitar Opcion Respuesta?"
          />
        </div>
      );
    } catch (err) {
      console.log('ListarOpcionesRespuesta render err');
      console.log(err);
      return (
        <div><label>{JSON.stringify(err)}</label></div>
      );
    }
  }

  return render();
}
