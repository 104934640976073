import React, { useState } from 'react';
import Plus from 'feather-icons-react/build/IconComponents/Plus';
import { ChevronDown } from 'feather-icons-react/build/IconComponents';
import { ESTILO_BOTON_LISTAR_ATRAS } from '../utils/const/estilos-const';

const DropdownExportarDetalleChecklist = ({ exportToExcel, exportToPdf }) => {
    const [isOpen, setIsOpen] = useState(false);

    const toggleDropdown = () => setIsOpen(!isOpen);

    const onPressOption = () => {

        setIsOpen(false);
    }

    return (
        <div className="relative inline-block text-left">
            <button
                className={ESTILO_BOTON_LISTAR_ATRAS}
                onClick={toggleDropdown}

            >
                <div className="relative">
                    <Plus
                        className={`transition-opacity duration-300 ease-in-out absolute ${isOpen ? 'opacity-0' : 'opacity-100'}`}
                    />
                    <ChevronDown
                        className={`transition-opacity duration-300 ease-in-out ${isOpen ? 'opacity-100' : 'opacity-0'}`}
                    />
                </div>
                <span className="ml-2">EXPORTAR</span>
            </button>
            {isOpen && (
                <div className="origin-top-right absolute right-0 mt-2 w-56 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none">
                    <div className="py-1" role="menu" aria-orientation="vertical" aria-labelledby="options-menu">
                        <a
                            className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100"
                            role="menuitem"
                            onClick={() => { onPressOption(); exportToExcel(); }}
                        >
                            EXPORTAR EXCEL
                        </a>
                        <a
                            className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100"
                            role="menuitem"
                            onClick={() => { onPressOption(); exportToPdf(); }}
                        >
                            IMPRIMIR
                        </a>
                    </div>
                </div>
            )}
        </div>
    );
};

export default DropdownExportarDetalleChecklist;
